import styled from 'styled-components';

export * from '../sharedStyle';

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Link = styled.a`
    font-size: 21px;
    color: #ffffff;
`;