import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH } from '../sharedStyle';
export * from '../sharedStyle';

export const ShowreelHolder = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        flex-direction: column-reverse;
    }
`;

export const Showreels = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-around;
    align-items: center;
    margin-left: 45px;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        margin-left: 0px;
        flex-direction: row;
        margin-bottom: 45px;
    }

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-bottom: 21px;
        flex-wrap: wrap;
    }
`;

export const Showreel = styled.div`
    position: relative;
    cursor: pointer;
    width: 234px;
    height: 94px;
    border: 1px solid #ffffff;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 142px;
        height: 57px;
        margin-bottom: 6px;
    }
`;

export const Image = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: ${({ opacity }) => opacity};
    transition: opacity 840ms ease-in-out;
    z-index: 1;
`;

export const ShowreelName = styled.div`
    position: absolute;
    bottom: 4px;
    color: #FFFFFF;
    font-weight: bold;
    left: 4px;
    z-index: 3;
`;