import React, { useState, useEffect } from 'react';
import {
    Content, Title, Holder, Categories, Category, Videos, Thumbnails, VideoName,
} from './index.style';
import { categories, commercialList } from './data';
import { Player } from './Player';
import { VideoThumbnail } from './VideoThumbnail';

const Commercials = () => {
    const [currentCategory, setCurrentCategory] = useState(categories[0].id);
    const [commercials, setCommercials] = useState([]);
    const [currentVideo, setCurrentVideo] = useState();

    useEffect(() => {
        const _commercials = commercialList.filter(({ category }) => category === currentCategory);
        setCommercials(_commercials);

        setCurrentVideo(_commercials[0]);
    }, [currentCategory]);

    return (
        <Content>
            <Title>Commercials</Title>

            <Holder>
                <Categories>
                    {categories.map(({ id, name }) =>
                        (<Category
                            current={(currentCategory === id).toString()}
                            onClick={() => setCurrentCategory(id)}
                            key={`cat-${id}`}
                        >{name}</Category>)
                    )}
                </Categories>

                <Videos>
                    {!!currentVideo && <Player video={currentVideo} />}

                    <Thumbnails>{commercials.map((commercial) => <VideoThumbnail
                        key={`vt-${commercial.providerKey}`}
                        title={commercial.title}
                        videoId={commercial.providerKey}
                        provider={commercial.provider}
                        onClick={() => setCurrentVideo(commercial)}
                    />)}</Thumbnails>
                </Videos>
                {!!currentVideo && <VideoName>{currentVideo.title}</VideoName>}
            </Holder>
        </Content>
    );
};

export default Commercials;