import React, { useState, useEffect } from 'react';
import { Thumbnail, ThumbnailTitle, Image } from './index.style';
import { providerTypes } from './data';

export const VideoThumbnail = ({ videoId, title, onClick, provider }) => {
    const [image, setImage] = useState();
    useEffect(() => {
        const getImage = async () => {
            if (provider === providerTypes.YouTube) {
                setImage(`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`);
            }
            else if (provider === providerTypes.Vimeo) {
                const res = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`);
                const data = await res.json();
                setImage(data[0].thumbnail_large);
            }
        };
        getImage();
    }, [provider, videoId]);
    return <Thumbnail onClick={onClick}>
        <Image alt={title} src={image} />
        <ThumbnailTitle>{title}</ThumbnailTitle>
    </Thumbnail>;
};
