import React from 'react';
import { useLocation } from 'react-router-dom';
import { Nav, NavLink } from './Navigator.style';

const Link = ({ to, label, pathname }) => {
    return (<NavLink to={to} current={(pathname === to).toString()}>{label}</NavLink>)
}

const Navigator = () => {
    const { pathname } = useLocation();

    return (
        <Nav>
            <Link to='/about' label='About' pathname={pathname} />
            <Link to='/' label='Commercials' pathname={pathname} />
            <Link to='/contact' label='Contact' pathname={pathname} />
            <Link to='/equipment' label='Equipment' pathname={pathname} />
            <Link to='/links' label='Links' pathname={pathname} />
            <Link to='/showreel' label='Showreel' pathname={pathname} />
        </Nav>
    );
};

export default Navigator;