import React from 'react';
import { Content, Title, Text } from './index.style';

const Equipment = () => {
    return (
        <Content>
            <Title>Equipment</Title>
            <Text>
                - Phantom Flex4K. 4K resolution at up to 1,000 fps Also supports standard, sync sound frame rates<br />
                Low noise, high dynamic range<br />
                Full-featured on-camera control interface<br />
                Multi-Channel 3G HG-SDI outputs<br />
                CineMag® IV support for raw recording<br />
                Now Available: Audio and Apple ProRes recording options<br />
                <br />
                - Set Of Liaca Lenses.   Ideal for Table top work. <br /><br />
                - Innovision Boroscope With set of Lenses.<br /><br />
                - "Mega Led Light" new invention by Benny Mali. High power light. <br />
                Allow you to shoot high speed photography with no heat and in low power.<br />
                I can shoot a real Icecream in 1000fps F stop 8 for an hour and the ice cream want melt.<br />
            </Text>
        </Content>
    );
};

export default Equipment;