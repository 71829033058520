import React, { useState, useEffect } from 'react';
import {
    ContactDetails, ContactLink, Content, Title, Form, Input, Textarea, 
    Submit
} from './index.style';

const Contact = () => {
    const [country, setCountry] = useState('');
    useEffect(() => {
        const lookup = async () => {
            const res = await fetch('http://ip-api.com/json');
            const data = await res.json();
            const { status, country } = data;

            if (status === 'success') {
                setCountry(country);
            }
        };
        lookup();
    }, []);

    return (
        <Content>
            <Title>Contact</Title>
            <ContactDetails>
                <ContactLink href='tel:+972-52-3255100'>+972-52-3255100</ContactLink>
                <ContactLink href='mailto:malbeng@gmail.com'>malbeng@gmail.com</ContactLink>
            </ContactDetails>
            

            <Form
                method='post'
                action='https://mailthis.to/malbeng@gmail.com'
            >
                <Input
                    type='text'
                    required name='name'
                    placeholder='Your Name'
                />
                <Input
                    type='email'
                    required
                    name='_replyto'
                    placeholder='Your Email'
                />
                <Textarea
                    required
                    name='message'
                    placeholder='Your Message'
                />
                <Input
                    type='hidden'
                    name='country'
                    value={country}
                />
                <Input
                    type='hidden'
                    name='_after'
                    value='https://bennymali.com/'
                />
                <Submit>Contact</Submit>
            </Form>
        </Content>
    );
};

export default Contact;