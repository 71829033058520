import React from 'react';
import ReactPlayer from 'react-player';
import { PlayerHolder } from './index.style';
import { providerTypes } from './data';

export const Player = ({ video }) => {
    const { provider, providerKey } = video;
    const url = (provider, providerKey) => {
        if (provider === providerTypes.YouTube) {
            return `https://www.youtube.com/watch?v=${providerKey}`;
        }
        else if (provider === providerTypes.Vimeo) {
            return `https://vimeo.com/${providerKey}`;
        }
    };
    return (<PlayerHolder>
        <ReactPlayer url={url(provider, providerKey)} width='100%' height='100%' playing loop controls />
    </PlayerHolder>);
};
