import styled from 'styled-components';
import TableTopPNG from './tabletop.png';
import { DESKTOP_WIDTH, TABLET_WIDTH, PHONE_WIDTH } from '../sharedStyle';

export const Container = styled.div`
    display: flex;
    height: 100%;
    width: ${DESKTOP_WIDTH}px;
    align-self: center;
    flex-direction:column;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: ${TABLET_WIDTH}px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: ${PHONE_WIDTH}px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #979AC7;
    border-top: 1px solid #979AC7;
    height: 65px;
    margin-top: 38px;
    color: #979AC7;
    flex: 1 0 auto;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 42px;
        margin-top: 10px;
    }
`;

export const Tabletop = styled.div`
    height: 54px;
    width: 304px;
    background-image: url(${TableTopPNG}); 
    align-self: center;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 24px;
        width: 230px;
        background-size: cover;
    }
`;

export const Name = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px 5px 0 0;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex: 1 0 auto;
        margin: 18px 2px 0 3px;
    }
`;

export const BennyMali = styled.div`
    font-size: 42px;
    font-weight: bold;
    text-transform: uppercase;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 25px;
    }
`;

export const DoP = styled.div`
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 4.5px;
    margin: 9px 2px 0;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 14px;
        letter-spacing: 2.4px;
        margin: 2px 2px 0;
    }
`;
