export const providerTypes = { 'YouTube': 1, 'Vimeo': 2 };

export const categoriesId = {
    'WhatsNew': 5,
    'Food': 1,
    'Chocolate': 2,
    'Liquids': 3,
    'Misc': 4,
    'LiveAction': 6
};

export const categories = [
    {
        id: categoriesId.WhatsNew,
        name: "What's New"
    }, {
        id: categoriesId.Food,
        name: 'Food'
    }, {
        id: categoriesId.Chocolate,
        name: 'Chocolate'
    }, {
        id: categoriesId.Liquids,
        name: 'Liquids'
    }, {
        id: categoriesId.Misc,
        name: 'Cars'
    }, {
        id: categoriesId.LiveAction,
        name: 'Misc & Live Action'
    }
];

export const commercialList = [
    {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '374879157',
        title: 'Prigat Fruit  soft drink',
        description: ''
    },{
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '266245898',
        title: 'RC Cola 2018',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '274410964',
        title: 'Ice tea Wissotzky red and Yellow',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '277253601',
        title: 'Red Smoothies Sunfrost',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '277253099',
        title: 'Green Smoothies Sunfrost',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '277254106',
        title: 'Yellow Smoothies Sanfrost',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '274412161',
        title: 'Sauter Oven baking ',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '274412307',
        title: 'Electric Cooktop Induction Sauter Dishes',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '228540870',
        title: 'Japanese Soya Sauce, Ramen',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '195756590',
        title: 'Landwer Cafe” Italian menu (Director Version) ',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '228540670',
        title: 'Italian Tomato Sauce Pizza',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '228708544',
        title: 'Prigat drink extra',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '255148514',
        title: 'Crackers',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '255149054',
        title: 'Minute steak',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '255148478',
        title: 'Schweppes New Drinks',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '255264842',
        title: 'chocolate blonde',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '228541122',
        title: 'Thai Sweet Chili Sauce',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '65244269',
        title: 'Tabletop Reel',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '228540504',
        title: 'Dutch Maionese Roast Beef sandwich',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '203844648',
        title: 'Nutri Ninja',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '191035772',
        title: 'Chocolate extra milk',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '193493453',
        title: 'Soya drink ',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150106608',
        title: 'sprite Lemon lime',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150106603',
        title: 'sprite cork 6',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150106602',
        title: 'sprite pouring',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150106604',
        title: 'Fuze Tea sensory',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150106605',
        title: 'Fuze Tea whirpool',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'ULkZwdwaQns',
        title: 'caesarstone',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '152876832',
        title: 'Torgoen watches Promo',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150063352',
        title: 'Bubble bar Tami4',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150063350',
        title: 'Renault Clio Leaf',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150063349',
        title: 'Renault Time sales event',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150063348',
        title: 'Pasta osem_perfecto',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '150063347',
        title: 'Yotveta cream',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '129303474',
        title: 'pizza hut Shakshuka',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '120933564',
        title: 'schweppes lime',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '117363777',
        title: 'Klick chocolate',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '120933366',
        title: 'barbari',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '120933565',
        title: 'Muller berry',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '120933567',
        title: 'Techina',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '120933566',
        title: 'Zuriel goat cheese 2',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '114257190',
        title: 'Renault Clio Estate',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '112883567',
        title: 'Muller Froop yogurt',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '112883566',
        title: 'Zuriel goat cheese',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '105891898',
        title: 'Gifts for holdays',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '106365360',
        title: 'CARMEL Winery',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99737072',
        title: 'TUBORG NEW BOTTEL',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '101485448',
        title: 'Renault Captur',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99268044',
        title: 'Renault Clio',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99268042',
        title: 'Carmel Winery',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99267848',
        title: 'Carlsberg Living room',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99268048',
        title: 'ice coffe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99268046',
        title: 'strawberies plus apple',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '99268043',
        title: 'Mayumana Multi',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '84169221',
        title: 'McDonald Junior taste',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '84168288',
        title: 'energy chocolate',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '84169220',
        title: 'McDonald comparing calories',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '84168287',
        title: 'energy strawberry yogurt',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '76813240',
        title: 'Carmit Chocolate and Nuts Rocks',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '76793599',
        title: 'Pastırma',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '76668339',
        title: 'This is my Juke',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '76668566',
        title: 'Delicacy',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '76668565',
        title: 'DURU BULGUR',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '69703098',
        title: 'Sanfrost',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '69703100',
        title: 'Cheese Cake Tara',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '69703099',
        title: 'McDonald 225g',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '60012362',
        title: 'Strawberry mousse yople',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '60012364',
        title: 'Osem spices powder',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '60122244',
        title: 'McDonald Broadway',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '58506673',
        title: 'RC Cola American Taste',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '58506958',
        title: 'RC Cola Over 100 years old',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '58506959',
        title: 'RC Cola Welcome to America',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '52562113',
        title: 'Tara Moo Flavors',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '45142021',
        title: 'Magnolia Silver Jewelry',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '36459019',
        title: 'Lavazza Amodo Mio',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '45142022',
        title: 'Jacobs Coffe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '36791921',
        title: 'Danesi caffe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '37290108',
        title: 'Crema Body Lotion',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'YJ4TG5xLb1s',
        title: 'Pesek Zeman',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'jW0mS9EBE7I',
        title: 'McFullrry choco crunch Slow-Down',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: '5U27ckr94jE',
        title: 'McFullrry Oreo Slow-Down',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.WhatsNew,
        providerKey: '36539498',
        title: 'Ice Coffe Singing Coffe beans',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'weYZpSRL7rg',
        title: 'Grohe',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'zc8-lQReD7o',
        title: 'Dominos Pizza',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'NeYaLZA2CnA',
        title: 'Shugi Bar',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'CH82UdVsvY8',
        title: 'MixFlex Telma Space Omri Kaspi',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'jJO6oWJ6fvU',
        title: 'MixFlex Fruits Omri Kaspi Demo',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'yWdUrUYvE2A',
        title: 'Cocoman Stars',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'GTuX_jrqjU8',
        title: 'Salad Olive Oil Etz Hazit',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'G2QtdYGijO4',
        title: 'Fish Olive Oil Etz Hazit',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'rL5-vpkOAdM',
        title: 'The Egg tgat went for a walk,oil',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.WhatsNew,
        providerKey: 'WS5qpUGUv-w',
        title: 'Pasta on the dance floor',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '195756590',
        title: 'Landwer Cafe” Italian menu (Director Version) ',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '228540870',
        title: 'Japanese Soya Sauce, Ramen',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '228540670',
        title: 'Italian Tomato Sauce Pizza',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '228541122',
        title: 'Thai Sweet Chili Sauce',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '228540504',
        title: 'Dutch Maionese Roast Beef sandwich',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '255148514',
        title: 'Crackers',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '255149054',
        title: 'Minute steak',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '193492535',
        title: 'Hummus Achla W ',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '150063348',
        title: 'Pasta osem_perfecto',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '150063347',
        title: 'Yotveta cream',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '129303474',
        title: 'pizza hut Shakshuka',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '112883566',
        title: 'Zuriel goat cheese',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '84169221',
        title: 'McDonald Junior taste',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '84169220',
        title: 'McDonald comparing calories',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '76793599',
        title: 'Pastırma',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '76668566',
        title: 'Delicacy',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '65742297',
        title: 'DURU BULGUR REKLAM',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '60122244',
        title: 'McDonald Broadway',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '60012364',
        title: 'Osem spices powder',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36459849',
        title: 'Olive Oil fish',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'rL5-vpkOAdM',
        title: 'The Egg that went for a walk,oil',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'zc8-lQReD7o',
        title: 'Dominos Pizza',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'NeYaLZA2CnA',
        title: 'Shugi Bar',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'pwrEO3fOaUA',
        title: 'Knorr Sauce',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'G2QtdYGijO4',
        title: 'Fish Olive Oil Etz Hazit',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'GTuX_jrqjU8',
        title: 'Salad Olive Oil Etz Hazit',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'NTDmcnSRQac',
        title: 'Chick-pea with tachina Achala',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Food,
        providerKey: 'WS5qpUGUv-w',
        title: 'Pasta on the dance floor',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36526435',
        title: 'Burger ranch The Bull',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36526437',
        title: 'Roast Beef',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36527033',
        title: 'Roldin Cheese cake',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36527034',
        title: 'Roldin Flutim',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36539499',
        title: 'Knorr mushrooms',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36540762',
        title: 'Knorr Vegetable souce',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592047',
        title: 'Knorr soups',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36539496',
        title: 'Soya sauce',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36527751',
        title: 'McDonalds Winter',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36540765',
        title: 'Cheese Dessert "EDEN"',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36591543',
        title: 'Cheese Dessert carmel "EDEN""',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36540764',
        title: 'McDonland Salad',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36540761',
        title: 'Kisco',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36591293',
        title: 'Cake preperation',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36589653',
        title: 'Burger King Hot',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36589651',
        title: 'Baklland Dried fruits',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592360',
        title: 'Toasted Cheese Footbal',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592359',
        title: 'Schulstad bread',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592358',
        title: 'Maadnot baking',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592045',
        title: 'PastaHut',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592044',
        title: 'Yachiaam salmi',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36593308',
        title: 'Vili Food',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36593033',
        title: 'souffle',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Food,
        providerKey: '36592369',
        title: 'Zoglobag Food',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '191035772',
        title: 'Chocolate extra milk',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '255264842',
        title: 'chocolate blonde',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '117363777',
        title: 'Klick chocolate',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '120933366',
        title: 'barbari',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '112883567',
        title: 'Muller Froop yogurt',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '84168287',
        title: 'energy strawberry yogurt',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '120933565',
        title: 'Muller berry',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'YJ4TG5xLb1s',
        title: 'Pesek Zeman',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'jW0mS9EBE7I',
        title: 'McFullrry choco crunch Slow-Down',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: '5U27ckr94jE',
        title: 'McFullrry Oreo Slow-Down',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'RaADyHhhuGY',
        title: 'Shugifun Tut Banana',
        description: 'Shugifun Tut Banana'
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'CH82UdVsvY8',
        title: 'MixFlex Telma Space Omri Kaspi',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'jJO6oWJ6fvU',
        title: 'MixFlex Fruits Omri Kaspi Demo',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'CJJwKtdHKtI',
        title: 'Kinamonim Telma ',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'gZdoDhVUsbY',
        title: 'Cornflex with tropical Fruits',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'W3lxSM_2VUw',
        title: 'Cornflex with nuts and banana',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Chocolate,
        providerKey: 'yWdUrUYvE2A',
        title: 'Cocoman Stars',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36425039',
        title: 'Duplo',
        description: 'Demo/Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36427314',
        title: 'Dr Oetker Paradis',
        description: 'Demo/Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591536',
        title: 'Chocolate stuffed Tablets',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36541366',
        title: 'Ulker Chocolate',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591298',
        title: 'Milki Chocolate mousse',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591296',
        title: 'Milki Strawbiers Banana',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591295',
        title: 'Milki Chocolate',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591294',
        title: 'Chocolate bar Click',
        description: 'Tabletop Director/Dp: Benny Mali'
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591542',
        title: 'Danone Fantsia',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591537',
        title: 'Danona winter',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36591541',
        title: 'czekodzemi Chocolate Jam',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Chocolate,
        providerKey: '36593035',
        title: 'Verios chocolate shots 2005',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '150106608',
        title: 'sprite Lemon lime',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '150106603',
        title: 'sprite cork 6',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '150106602',
        title: 'sprite pouring',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '150106604',
        title: 'Fuze Tea sensory',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '150106605',
        title: 'Fuze Tea whirpool',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '255148478',
        title: 'Schweppes New Drinks',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '120933564',
        title: 'schweppes lime',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '99737072',
        title: 'TUBORG NEW BOTTEL',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '99268042',
        title: 'Carmel Winery',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '99267848',
        title: 'Carlsberg Living room',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '99268046',
        title: 'strawberies plus apple',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '99268048',
        title: 'ice coffe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '60012362',
        title: 'Strawberry mousse yople',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '58506673',
        title: 'RC Cola American Taste',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '58506958',
        title: 'RC Cola Over 100 years old',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '58506959',
        title: 'RC Cola Welcome to America',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '106365360',
        title: 'CARMEL Winery',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36791921',
        title: 'Danesi caffe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36539498',
        title: 'Ice Coffe Singing Coffe beans',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Liquids,
        providerKey: 'weYZpSRL7rg',
        title: 'Grohe',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36526436',
        title: 'Soda Stream',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36527749',
        title: 'Schweppes',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36539497',
        title: 'Crema fruits',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36539495',
        title: 'Primore Orange Juice',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36541364',
        title: 'Pepsi Max "drops"',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592361',
        title: 'Hot Intro Summer Olives',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592903',
        title: 'Hot Intro Summer cherry',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592905',
        title: 'Hot Intro Summer Ice cubes',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592904',
        title: 'Hot Intro Summer Lemon',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592906',
        title: 'Hot Intro Summer Ice Cubes',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Liquids,
        providerKey: '36592907',
        title: 'Ice tea',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.Liquids,
        providerKey: 'jcVgdcCl-YI',
        title: 'Coffe Landver',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '150063350',
        title: 'Renault Clio Leaf',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '150063349',
        title: 'Renault Time sales event',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '101485448',
        title: 'Renault Captur',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '99268044',
        title: 'Renault Clio',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '114257190',
        title: 'Renault Clio Estate',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '76668339',
        title: 'This is my Juke',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '36528375',
        title: 'PEUGEOT 206 see the sky',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.Misc,
        providerKey: '36527752',
        title: 'Peugeot Sensor',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '105891898',
        title: 'Gifts for holdays',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.LiveAction,
        providerKey: 'ULkZwdwaQns',
        title: 'caesarstone',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '152876832',
        title: 'Torgoen watches Promo',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36737574',
        title: 'Toshiba Ladybird (beetle) II',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36737575',
        title: 'Toshiba Ladybird (beetle)',
        description: ''
    }, {
        provider: providerTypes.YouTube,
        category: categoriesId.LiveAction,
        providerKey: '1PVq47jOGFc',
        title: 'Intro Finger Tips',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36526439',
        title: 'The Cane',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36539384',
        title: 'Gaz Company',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '99268043',
        title: 'Mayumana Multi',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '45142021',
        title: 'Magnolia Silver Jewelry',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36427317',
        title: 'Zer4U Bride',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36427315',
        title: 'Zer4U Famely picture',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36427316',
        title: 'Zer4U Flowers Girl',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '37290108',
        title: 'Crema Body Lotion',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36528373',
        title: 'Explosions in super slow motion',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36540763',
        title: 'Romanson Watches',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36527032',
        title: 'Mooi Cookeis',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36527035',
        title: 'Sano Clear',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36527036',
        title: 'Sano Forte',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36527754',
        title: 'Status Fashion',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36589650',
        title: 'Analist Banking',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36541362',
        title: 'Tadiran Hot',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36589655',
        title: 'Mousse Lady',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36589652',
        title: 'Barak 013',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36541368',
        title: 'Amdocs',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36593029',
        title: 'Hot Intro Winter',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36593030',
        title: 'California Nuts',
        description: ''
    }, {
        provider: providerTypes.Vimeo,
        category: categoriesId.LiveAction,
        providerKey: '36593032',
        title: 'Anti Durgs Aid',
        description: ''
    }
];
