import React from 'react';
import { links } from './data';
import { Content, Title, LinksContainer, Link } from './index.style';

const Links = () => {
    return (
        <Content>
            <Title>Links</Title>
            <LinksContainer>{links.map(({ href, name }, idx) =>
                <Link
                    key={`link-${idx}`}
                    target='_blank'
                    href={href}
                >{name}</Link>
            )}</LinksContainer>
        </Content>
    );
};

export default Links;