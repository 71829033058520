import styled from 'styled-components';

export * from '../sharedStyle';

export const ContactDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 21px;
`;

export const ContactLink = styled.a`
    font-size: 21px;
    color: #ffffff;
    text-decoration: none;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const Input = styled.input`
    background-color: transparent;
    border: 2px solid #ffffff;
    margin-bottom: 12px;
    font-size: 21px;
    color: #ffffff;
    padding: 2px 4px;
`;

export const Textarea = styled.textarea`
    background-color: transparent;
    border: 2px solid #ffffff;
    margin-bottom: 12px;
    font-size: 21px;
    color: #ffffff;
    padding: 2px 4px;
    height: 150px;
`;

export const Submit = styled.button`
    font-size: 21px;
`;