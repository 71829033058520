import React from 'react';
import {
    Content, Title, AboutBen, BennyPicture, AboutBody
} from './index.style';

const START_YEAR = 1980;
const CURRENT_YEAR = new Date().getFullYear();

const About = () => {
    return (
        <Content>
            <Title>About</Title>

            <AboutBody>
                <BennyPicture />
                <AboutBen>
                    Feel free to visit my Showreels & Commercials section that contain some of my work.<br />
                    Having spend over {CURRENT_YEAR - START_YEAR} years in the film business, working on movies and commercials<br />
                    I have developed a particular interest in shooting food appeal, still life, cars - in short, Table Top work - with a specialization in hi speed photography<br />
                    Working as a Director/DOP, my passion is to apply innovative techniques to the solving of complex film issues. I like to work quickly and am able to operate very effectively under most conditions.<br />
                    I supervise my own team a very good model makers, SFX technicians - with their own workshop resources - as well as food stylists. I have also worked frequently in many different countries and have often utilized exclusively local skills.
            </AboutBen>

            </AboutBody>
        </Content>
    );
};

export default About;