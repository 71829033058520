import React, { useState } from 'react';
import { Showreel, Image, ShowreelName } from './index.style';

export const SelectableShowreel = ({ showreelIndex, setShowreelIndex, idx, name, images }) => {
    const [inHover, setInHover] = useState(false);
    return (<Showreel
        onClick={() => setShowreelIndex(idx)}
        onMouseEnter={() => setInHover(true)}
        onMouseLeave={() => setInHover(false)}
    >
        <Image src={images[0]} opacity={1} />
        <Image src={images[1]} opacity={(showreelIndex === idx || inHover) ? 1 : 0} />
        <ShowreelName>{name}</ShowreelName>
    </Showreel>);
};
