import styled from 'styled-components';
export const DESKTOP_WIDTH = 1020, TABLET_WIDTH = 742, PHONE_WIDTH = 300;

export const Content = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex: 100 1 auto;
`;

export const Title = styled.h1`
    color: #848BA4;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 42px;
    padding-top: 23px;
    padding-left: 16px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 18px;
        padding-top: 10px;
        padding-left: 6px;
        margin-bottom: 6px;
    }
`;
