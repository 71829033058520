import React from 'react';
import { BrowserRouter as DomRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Container, Header, Tabletop, Name, BennyMali, DoP } from './index.style';
import Navigator from './Navigator';
import Commercials from '../Commercials';
import About from '../About';
import Contact from '../Contact';
import Equipment from '../Equipment';
import Links from '../Links';
import Showreel from '../Showreel';

const Router = () => {
    return (
        <Container>
            <Header>
                <Tabletop />
                <Name>
                    <BennyMali>Benny Mali</BennyMali>
                    <DoP>Director - D. O. P</DoP>
                </Name>
            </Header>

            <DomRouter>
                <Switch>
                    <Route exact path='/'>
                        <Commercials />
                    </Route>
                    <Route exact path='/about'>
                        <About />
                    </Route>
                    <Route exact path='/contact'>
                        <Contact />
                    </Route>
                    <Route exact path='/equipment'>
                        <Equipment />
                    </Route>
                    <Route exact path='/links'>
                        <Links />
                    </Route>
                    <Route exact path='/showreel'>
                        <Showreel />
                    </Route>

                    <Route path='*'>
                        <Redirect to='/' />
                    </Route>
                </Switch>
                <Navigator />
            </DomRouter>
        </Container>
    );
};

export default Router;
