import images from './images';
import { providerTypes } from '../Commercials/data';

export const showreels = [{
    name: 'Food',
    provider: providerTypes.Vimeo,
    providerKey: '36405601',
    images: [images.Food1, images.Food2],
}, {
    name: 'Chocolate',
    provider: providerTypes.Vimeo,
    providerKey: '36405757',
    images: [images.Chocolate1, images.Chocolate2],
}, {
    name: 'Liquids',
    provider: providerTypes.Vimeo,
    providerKey: '36394210',
    images: [images.Liquids1, images.Liquids2],
}];