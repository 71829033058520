import React, { Fragment } from 'react';
import { GlobalStyle } from './GlobalStyle';
import Router from './Router';
const App = () => {
    return (
        <Fragment>
            <GlobalStyle />
            <Router />
        </Fragment>
    );
}

export default App;