import Chocolate1 from './chocolate1.jpg';
import Chocolate2 from './chocolate2.jpg';
import Food1 from './food1.jpg';
import Food2 from './food2.jpg';
import Liquids1 from './liquids1.jpg';
import Liquids2 from './liquids2.jpg';

export default {
    Chocolate1,
    Chocolate2,
    Food1,
    Food2,
    Liquids1,
    Liquids2,
};