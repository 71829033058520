import styled from 'styled-components';
import Benny from './benny.png';
import { TABLET_WIDTH } from '../sharedStyle';
export * from '../sharedStyle';

export const AboutBody = styled.div`
    display: flex;
    flex-direction: row;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        flex-direction: column-reverse;
    }
`;

export const AboutBen = styled.div`
    font-size: 18px;
    color: #CCCCFF;
    width: 475px;
    margin-left: 98px;
    margin-top: 26px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-left: 0px;
        width: 100%;
        font-size: 14px;
    }
`;

export const BennyPicture = styled.div`
    height: 300px;
    width: 405px;
    background-image: url(${Benny});  
    margin-top: 48px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-top: 5px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 10px;
    }
`;