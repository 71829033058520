import React, { useState } from 'react';
import { Content, Title, ShowreelHolder, Showreels } from './index.style';
import { showreels } from './data';
import { Player } from '../Commercials/Player';
import { SelectableShowreel } from './SelectableShowreel';

export const Showreel = () => {
    const [showreelIndex, setShowreelIndex] = useState(0);

    return (
        <Content>
            <Title>Showreel</Title>
            <ShowreelHolder>
                <Player video={showreels[showreelIndex]} />
                <Showreels>{showreels.map(({ name, images }, idx) => <SelectableShowreel
                    key={`showreel-clk-${idx}`}
                    showreelIndex={showreelIndex}
                    setShowreelIndex={setShowreelIndex}
                    idx={idx}
                    name={name}
                    images={images}
                />)}</Showreels>
            </ShowreelHolder>
        </Content>
    );
};

export default Showreel;