import styled from 'styled-components';
import { DESKTOP_WIDTH, TABLET_WIDTH } from '../sharedStyle';

export * from '../sharedStyle';

export const Holder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1 0 auto;
`;

export const Categories = styled.div`
    display: flex;
    align-self: center;
`;

export const Category = styled.div`
    color: ${({ current }) => current === 'true' ? '#9494c4' : '#ffffff'};
    font-size: 24px;
    align-self: center;
    margin-right: 7px;
    border-right: 1px solid #ffffff;
    padding-right: 7px;
    cursor: pointer;
    transition: color 420ms ease-in-out;
    margin-top: -55px;

    :hover {
        color: #9494c4;
    }
    
    :last-of-type {
        border-right: 0;
    }

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 8px;
        margin-top: -10px;
    }
`;
 
export const Videos = styled.div`
    display: flex;
    flex-direction: row;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        flex-direction: column-reverse;
    }
`;

export const Thumbnails = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 430px;
    width: 330px;
    overflow-x: auto;
    margin-left: 45px;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        height: 180px;
        width: 100%;
        margin-left: 0;
        overflow-y: auto;
        flex-direction: column;
    }

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 130px;
    }
`;

export const Thumbnail = styled.div`
    cursor: pointer;
    width: 92px;
    height: 66px;
    margin: 0 0 11px 8px;
    box-shadow: 0 0 2px grey;
    border: 5px solid #333;
    position: relative;

    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 85px;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        width: 80px;
        height: 50px;
        margin: 0 0 2px 9px;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
`;

export const ThumbnailTitle = styled.div`
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    text-align: center;
    color: #fff;
    font-size: 13px;
    text-shadow: -1px 1px 10px black;
    opacity: 0;
    transition: opacity 420ms ease-in-out;

    ${Thumbnail}:hover & {
        opacity: 1;
    }
`;

export const PlayerHolder = styled.div`
    display: flex;
    width: 739px;
    height: 411px;
    flex: 1 0 auto;
    box-shadow: 0 0 4px grey;
    justify-content: center;
    align-items:center;
    padding: 5px 0;
    
    @media only screen and (max-width: ${DESKTOP_WIDTH}px) {
        width: 100%;
    }
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        height: 200px;
    }
`;

export const VideoName = styled.div`
    color: #ffffff;
    font-size: 26px;
    align-self: center;
    text-align: center;
    margin-bottom: 42px;
    
    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 20px;
        margin-bottom: 0px;
    }
`;