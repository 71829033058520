import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TABLET_WIDTH } from '../sharedStyle';

export const Nav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    margin-bottom: 42px;

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        margin-bottom: 12px;
    }
`;

export const NavLink = styled(Link)`
    color: ${({ current }) => current === 'true' ? '#9494c4' : '#ffffff'};
    text-decoration: none;
    transition: color 420ms ease-in-out;
    
    :hover {
        color: #9494c4;
        
        ::before {
            background-color: #9494c4;
        }
    }

    ::before {
        content: "";
        width: 10px;
        height: 10px;
        transition: background-color 420ms ease-in-out;
        background-color: ${({ current }) => current === 'true' ? '#9494c4' : '#ffffff'};
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
    }

    @media only screen and (max-width: ${TABLET_WIDTH}px) {
        font-size: 11px;
        
        ::before {
            width: 3px;
            height: 3px;
            margin-right: 1px;
        }
    }
`;